.c-cards {
    background-color: #252536;
    background-repeat: repeat;
    box-shadow: inset 0 5px 25px 0 #000c;
    margin-top: 22px;
    border-bottom: dashed 1px #ff274a;
    margin-bottom: 40px;

    &__content {
        padding: 1px 5px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 4px 0;
    }

    &__item {
        width: 25%;
        position: relative;

        &:not(:last-child)::after {
            position: absolute;
            top: 5px;
            height: calc(100% - 10px);
            width: 1px;
            background-color: #313047;
            right: 0;
            display: block;
            content: "";
        }

        &_info {
            & a {
                font-weight: 800;
                border-radius: 5px;
                font-size: 14px;
                height: 55px;
                display: flex;
                align-items: center;
            }

            &_blue {
                color: #17eada;

                & img {
                    height: 20px;
                    margin-bottom: 4px;
                }
            }

            &_red {
                color: #de593e;

                & img {
                    height: 25px;
                    margin-bottom: 4px;
                }
            }

            &_green {
                color: #6ef770;

                & img {
                    height: 25px;
                    margin-bottom: 4px;
                }
            }

            &_yellow {
                color: #e8ce3a;

                & img {
                    height: 25px;
                    margin-bottom: 4px;
                }
            }

            & span:first-child {
                height: 100%;
                justify-content: center;
                flex-direction: column;
                display: flex;
                align-items: center;
                width: 100%;
            }
        }
    }
}