.c-text {
	&-container {
		border-radius: 10px;
	}

	margin-bottom: 25px;

	& img {
		margin: auto;
	}

	& p {
		margin-bottom: 10px;
		font-size: $font-size-base;
	}

	& ul {
		padding-left: 25px;
		list-style-type: disc;
		font-size: $font-size-base;
		margin-bottom: 15px;
	}

	& ol {
		padding-left: 25px;
		list-style-type: decimal;
		font-size: $font-size-base;
		margin-bottom: 15px;
	}

	& a:not([class]) {
		color: $link-hover;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.o-text-image {

		&_right {
			float: right;
			max-width: 40vw;
		}

		&_left {
			float: left;
			max-width: 40vw;
		}
	}

	& details[open] .c-table-icon {
		rotate: 180deg;
	}

	& details[open] summary {
		border-radius: 16px 16px 0 0;
	}
}


.c-table-of-content {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	list-style: decimal;
	flex-direction: column;
	padding: 25px 20px 15px 60px !important;
	background: #1b1b27;
	box-shadow: 0 2px 2px 0 #0000003D;
	border-radius: 0 0 16px 16px;

	& li {
		margin-bottom: 10px;
		padding-left: 5px;

		&::marker {
			font-weight: 500;
			color: #fe284a;
		}
	}

	& a:not([class]) {
		color: rgba(255, 255, 255, .8);
		font-weight: 500;

		&:hover {
			text-decoration: underline #fe284a;
		}
	}

	&__wrapper {
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 20px 0 30px 0;

		& summary {
			cursor: pointer;
			background-color: #252536;
			font-weight: 700;
			border-radius: 16px;
			justify-content: space-between;
			align-items: center;
			font-size: 24px;
			padding: 10px 20px;
			display: flex;
			z-index: 1;
			box-shadow: 0 2px 2px 0 #0000003D;

			& svg {
				width: 18px;
				height: 14px;
				fill: #fff;
				margin-left: 8px;
				transition: rotate .15s ease-in;
				flex-shrink: 0;
			}
		}
	}

	&__item {
		width: 100%;
		margin-bottom: 10px;


		&:nth-child(odd) {
			padding-right: 25px;
		}
	}
}