.c-comment {
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border: dashed 1px #ff274a;
	background-color: #1b1b27;
	border-radius: 4px;
	padding: 10px;
	margin-bottom: 10px;
	& a:not([class])  {
		color: $link-hover;
		text-decoration: none;
		&:hover, &:focus {
				text-decoration: underline;
		}
	}
	&s {
		margin-bottom: 30px;
	}
	&__name {
		width: 100%;
		text-align: left;
		font-size: 2rem;
	}
	&__content {
		padding: 10px;
		display: -webkit-inline-box;
		display: inline-flex;
		text-align: left;
		width: 100%;
	}
	& .o-btn {
		margin-left: auto;
	}
}
