.c-slot {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 5px;
	margin-bottom: 15px;
	float: left;
	width: calc(100% / 2);
	height: auto;
	transition: .2s ease-in-out;

	&s {
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		justify-content: center;
		align-items: stretch;
		flex-direction: row;
		flex-wrap: wrap;
		max-width: 450px;
		margin: 0 auto;
		margin-bottom: 25px;

		&__games_button {
			font-size: 12px;
			height: 30px;
			padding: 0 30px;
			background-color: #4d5a85;
			box-shadow: 0 3px 0 0 #2c3552, 0 4px 4px 0 #000;
			border-radius: 50px;
			font-weight: 800;
			letter-spacing: 1px;
			line-height: 22px;
			text-transform: uppercase;
			align-items: center;
			display: flex;
			margin: 0 auto;
			margin-bottom: 50px;
			width: 140px;
			&:hover {
				box-shadow: 0 3px 0 0 #2c3552, 0 4px 4px 0 #000, inset 0 15px 15px -3px #fff6;
			}
		}
	}

	&__icon {
		width: 100%;
		border-radius: 4px 4px 0 0;
		overflow: hidden;
		border: 1px solid #393a51;
		border-bottom: none;
		height: 75%;
		position: relative;

		& img {
			width: 100%;
			height: 100%;
			border-radius: 4px 4px 0 0;
			overflow: hidden;
		}
	}

	&__new {
		position: absolute;
		right: 15px;
		top: 10px;
		max-width: 40px;
		margin-left: auto;
		background: #ff5100;
		color: $black;
		padding: 0 6px;
		margin-bottom: 2px;
		line-height: 26px;
		font-size: 1.1rem;
		border-radius: 3px;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;
	}

	&__name {
		background-color: #161626;
		border: 1px solid #393a51;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		border-top: none;
		box-sizing: border-box;
		display: flex;
		flex: none;
		flex-direction: column;
		width: 100%;
		font-size: 14px;
		font-weight: 500;
		height: 25%;
		justify-content: center;
		overflow: hidden;
		overflow-wrap: break-word;
		padding: 0 5px;
		text-align: center;
		text-overflow: ellipsis;
	}

	&:hover &__overflow {
		opacity: 1;
	}

	&:hover {
		scale: 1.1;
	}

	&__overflow {
		opacity: 0;
		display: block;
		background-color: #000c;
		transition: 200ms;
		text-align: center;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		z-index: 10;

		& a {
			transition: transform .2s ease-in;

			&:hover {
				transform: scale(1.1);
			}
		}

		& .o-btn_green {
			font-size: 12px;
			padding: 0 10px;
		}
	}

	&__overflow-play {
		width: 64px;
		height: 64px;
		margin-bottom: 12px;
		border-radius: 50%;
		transition: all .3s linear;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
		text-align: center;
		text-decoration: none;
		font-size: 1.4rem;
		align-items: center;

		&:hover {
			transform: scale(1.03);
		}
	}

	&__overflow-demo {
		background-color: #313047;
		border-radius: 50px;
		box-sizing: border-box;
		font-size: 11px;
		font-weight: 700;
		height: 23px;
		line-height: 25px;
		padding: 0 20px;
		text-transform: uppercase;

		&:hover {}
	}
}


@media screen and (min-width: 668px) {
	.c-slot {
		width: 217px;
		height: 217px;
	}
}