// @media screen and (min-width: 390px) {}

@media screen and (min-width: 492px) {
    .c-slot {
        &__overflow {
            & .o-btn_green {
                font-size: 16px;
                padding: 0 30px;
            }
        }
    }
}

@media screen and (min-width: 668px) {
    html {
        scroll-padding-top: 50px;
    }

    .c-header {
        flex-direction: row;
        background-color: rgba(37, 37, 54, .95);
        padding-bottom: unset;

        &__content {
            flex-direction: initial;
        }

        &__buttons {
            margin-left: auto;
            justify-content: unset;
            width: unset;
            border-top: unset;
        }

        &__help {
            & span {
                display: flex;
            }
        }
    }

    .c-banner {
        box-shadow: 0 6px 30px 0 #000c;
        max-height: initial;
        margin-top: initial;
        padding-top: initial;
        height: 300px;


        &__content {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            & img:first-child {
                object-fit: cover;
                object-position: 50% 50%;
                width: 100%;
                height: 100%;
            }
        }

        &__money {
            &_amount {
                font-size: 25px;
            }

            &_card {
                &s {
                    overflow-x: unset;
                    margin-top: 0;
                    height: auto;
                    max-height: unset;
                }

                &_major {
                    background-size: 180px 88px;
                    height: 88px;
                    transform: translateY(20px);
                    width: 180px;
                }

                &_mega {
                    background-size: 203px 88px;
                    height: 88px;
                    margin: 0 20px;
                    width: 203px;
                }

                &_minor {
                    width: 180px;
                    height: 88px;
                    transform: translateY(20px);
                    background-size: 180px 88px;
                }
            }
        }

        &__subtitle {
            background: linear-gradient(270.09deg, #06104f00 .07%, #06104fe6 10.06%, #06104fe6 89.94%, #06104f00 99.93%);

            & span {
                padding-top: initial;
                display: flex;
                align-items: center;
                font-weight: 800;
                height: 56px;
                font-size: 26px;
                line-height: 34px;
                -webkit-text-fill-color: #0000;
                background: linear-gradient(180deg, #fff176, #ffc40f 50%, #f5b401 50.01%, #ffee5b);
                -webkit-background-clip: text;
            }
        }
    }

    .c-cards {
        margin-top: unset;

        &__list {
            margin: 0 auto;
            max-width: 1200px;
        }

        &__item {
            &_info {
                & span:first-child {
                    font-size: 20px;
                    padding-left: initial;
                    justify-content: center;
                    flex-direction: initial;
                }

                & img {
                    margin-bottom: initial;
                    margin-right: 12px;
                }


                &_blue {
                    & img {
                        height: auto;
                        width: 52px;
                    }
                }

                &_red {
                    & img {
                        height: auto;
                        width: 40px;
                    }
                }

                &_green {
                    & img {
                        height: auto;
                        width: 37px;
                    }
                }

                &_yellow {
                    & img {
                        height: auto;
                        width: 37px;
                    }
                }

            }


        }
    }

    .c-table-of-content {
        flex-direction: row;

        &__item {
            width: 50%;
        }
    }

    .c-slot {
        &s {
            max-width: unset;
        }
    }

    .c-footer {
        &__upper {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            padding-bottom: 30px;
            padding-top: 30px;
            flex-direction: row;
            width: 100%;
            border-bottom: dashed 1px #ff274a;

            &_help {
                justify-content: unset;
                margin-bottom: unset;
            }
        }

        &__lower {
            &_copyright {
                &__info {
                    display: flex;
                    justify-content: space-between;
                    max-width: 100%;
                }

                &__license {
                    margin-bottom: unset;
                    margin-right: 35px;
                }
            }

            &_payments {
                & ul li {
                    height: 60px;
                    min-width: 110px;
                }
            }
        }
    }
}

// @media screen and (min-width: 768px) {}

// @media screen and (min-width: 992px) {}

@media screen and (min-width: 1024px) {
    .c-footer {
        &__upper {
            padding-right: 10px;
            padding-left: 10px;
        }

        &__lower {
            padding: 0 20px;

            &_copyright {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;

                &__info {
                    max-width: 950px;
                    justify-content: flex-start;
                }
            }
        }
    }
}

// @media screen and (min-width: 1200px) {}