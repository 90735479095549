@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerUltraLight.eot');
    src: local('Muller UltraLight'), local('MullerUltraLight'),
        url('../fonts/Muller/MullerUltraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerUltraLight.woff2') format('woff2'),
        url('../fonts/Muller/MullerUltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerBoldItalic.eot');
    src: local('Muller Bold Italic'), local('MullerBoldItalic'),
        url('../fonts/Muller/MullerBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerBoldItalic.woff2') format('woff2'),
        url('../fonts/Muller/MullerBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerMedium.eot');
    src: local('Muller Medium'), local('MullerMedium'),
        url('../fonts/Muller/MullerMedium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerMedium.woff2') format('woff2'),
        url('../fonts/Muller/MullerMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerExtraBoldItalic.eot');
    src: local('Muller ExtraBold Italic'), local('MullerExtraBoldItalic'),
        url('../fonts/Muller/MullerExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Muller/MullerExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerUltraLightItalic.eot');
    src: local('Muller UltraLight Italic'), local('MullerUltraLightItalic'),
        url('../fonts/Muller/MullerUltraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerUltraLightItalic.woff2') format('woff2'),
        url('../fonts/Muller/MullerUltraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerRegular.eot');
    src: local('Muller Regular'), local('MullerRegular'),
        url('../fonts/Muller/MullerRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerRegular.woff2') format('woff2'),
        url('../fonts/Muller/MullerRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerBlackItalic.eot');
    src: local('Muller Black Italic'), local('MullerBlackItalic'),
        url('../fonts/Muller/MullerBlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerBlackItalic.woff2') format('woff2'),
        url('../fonts/Muller/MullerBlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Hairline';
    src: url('../fonts/Muller/MullerHairlineItalic.eot');
    src: local('Muller Hairline Italic'), local('MullerHairlineItalic'),
        url('../fonts/Muller/MullerHairlineItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerHairlineItalic.woff2') format('woff2'),
        url('../fonts/Muller/MullerHairlineItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerHeavyItalic.eot');
    src: local('Muller Heavy Italic'), local('MullerHeavyItalic'),
        url('../fonts/Muller/MullerHeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerHeavyItalic.woff2') format('woff2'),
        url('../fonts/Muller/MullerHeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerThinItalic.eot');
    src: local('Muller Thin Italic'), local('MullerThinItalic'),
        url('../fonts/Muller/MullerThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerThinItalic.woff2') format('woff2'),
        url('../fonts/Muller/MullerThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Hairline';
    src: url('../fonts/Muller/MullerHairline.eot');
    src: local('Muller Hairline'), local('MullerHairline'),
        url('../fonts/Muller/MullerHairline.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerHairline.woff2') format('woff2'),
        url('../fonts/Muller/MullerHairline.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerHeavy.eot');
    src: local('Muller Heavy'), local('MullerHeavy'),
        url('../fonts/Muller/MullerHeavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerHeavy.woff2') format('woff2'),
        url('../fonts/Muller/MullerHeavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerThin.eot');
    src: local('Muller Thin'), local('MullerThin'),
        url('../fonts/Muller/MullerThin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerThin.woff2') format('woff2'),
        url('../fonts/Muller/MullerThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerLightItalic.eot');
    src: local('Muller Light Italic'), local('MullerLightItalic'),
        url('../fonts/Muller/MullerLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerLightItalic.woff2') format('woff2'),
        url('../fonts/Muller/MullerLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerLight.eot');
    src: local('Muller Light'), local('MullerLight'),
        url('../fonts/Muller/MullerLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerLight.woff2') format('woff2'),
        url('../fonts/Muller/MullerLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerBlack.eot');
    src: local('Muller Black'), local('MullerBlack'),
        url('../fonts/Muller/MullerBlack.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerBlack.woff2') format('woff2'),
        url('../fonts/Muller/MullerBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerBold.eot');
    src: local('Muller Bold'), local('MullerBold'),
        url('../fonts/Muller/MullerBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerBold.woff2') format('woff2'),
        url('../fonts/Muller/MullerBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerExtraBold.eot');
    src: local('Muller ExtraBold'), local('MullerExtraBold'),
        url('../fonts/Muller/MullerExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerExtraBold.woff2') format('woff2'),
        url('../fonts/Muller/MullerExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Regular';
    src: url('../fonts/Muller/MullerRegularItalic.eot');
    src: local('Muller Regular Italic'), local('MullerRegularItalic'),
        url('../fonts/Muller/MullerRegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerRegularItalic.woff2') format('woff2'),
        url('../fonts/Muller/MullerRegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerMediumItalic.eot');
    src: local('Muller Medium Italic'), local('MullerMediumItalic'),
        url('../fonts/Muller/MullerMediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Muller/MullerMediumItalic.woff2') format('woff2'),
        url('../fonts/Muller/MullerMediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}