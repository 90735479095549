*,
*:before,
*:after {
    box-sizing: border-box;
}

*:focus {
    outline: none !important;
}

html {
    font-size: 62.5%;
    scroll-padding-top: 100px;
}

body {
    color: $white;
    font-family: "Muller", sans-serif;
    background-color: #212132;
    font-size: 1.6rem;
}

.svg-none {
    display: none;
}

.o-text {
    &-bold {
        font-weight: bold;
    }

    &-center {
        text-align: center;
    }

    &-up {
        text-transform: uppercase;
    }
}

img {
    max-width: 100%;
    height: auto;
}

button,
a {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: inherit;

    & picture img:hover {
        transform: scale(1.02);
    }
}

h1,
.h1 {
    font-size: $h1-font-size;
}

h2,
.h2 {
    font-size: $h2-font-size;
}

h3,
.h3 {
    font-size: $h3-font-size;
}

h4,
.h4 {
    font-size: $h4-font-size;
}

h5,
.h5 {
    font-size: $h5-font-size;
}

h6,
.h6 {
    font-size: $h6-font-size;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: .2s;
}

.o-btn {
    align-items: center;
    border: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    height: 40px;
    justify-content: center;
    letter-spacing: 1px;
    line-height: 22px;
    outline: none;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    &_white {
        align-items: center;
        background-color: #0000;
        border-bottom: 1px solid #fff;
        color: inherit;
        cursor: pointer;
        height: 20px;
        text-decoration: none;
        text-transform: uppercase;
    }

    &_red {
        background-color: #fe284a;
        box-shadow: 0 3px 0 0 #871628, 0 4px 4px 0 #000;
        height: 30px;
        padding: 0 30px;
        font-size: 12px;
        border-radius: 50px;
        padding: 0 30px;
        font-weight: 800;

        &:hover {
            box-shadow: 0 3px 0 0 #871628, 0 4px 4px 0 #000, inset 0 15px 15px -3px #fff6;
        }
    }

    &_green {
        background-color: #62c23c;
        box-shadow: 0 3px 0 0 #3c7028, 0 4px 4px 0 #000;
        font-size: 16px;
        font-weight: 600;
        height: 30px;
        padding: 0 30px;
        border-radius: 50px;

        &:hover {
            box-shadow: 0 3px 0 0 #3c7028, 0 4px 4px 0 #000, inset 0 15px 15px -3px #fff6;
        }
    }
}