$grid-breakpoints: (
   sm: 576px,
   md: 768px,
   lg: 992px,
   xl: 1200px,
);
$container-max-widths: (
   xl: 1200px,
);

@each $point in sm, md, lg, xl {
  @media screen and (min-width: map-get($grid-breakpoints, $point)) {
     .o-container {
        max-width: map-get($container-max-widths, $point);
     }
  }
}


//
// $p:                                                 40px;       //40px
// $p-xs:                                              $p / 8;     //5px
// $p-sm:                                              $p / 2;     //20px
// $p-md:                                              $p-sm * 3;  //60px
// $p-lg:                                              $p * 2;     //80px
//
// $m:                                                 30px;       //30px
// $m-xs:                                              $m / 3;     //10px
// $m-sm:                                              $m / 2;     //15px
// $m-md:                                              $m-sm * 3;  //45px
// $m-lg:                                              $m * 2;     //60px
//

$link-hover:                                    #ffeeaa;
$gray:                                              #f2f2f2;
$dark-gray:                                     #303030;
$black:                                             #000000;
$white:                                             #ffffff;


// html font-size: 10px;

$font-size-base:                                    1.6rem;
$font-size-sm:                                      1.4rem;
$font-size-md:                                      1.8rem;
$font-size-lg:                                      2.2rem;


$h1-font-size:                                      3rem;
$h2-font-size:                                      2.8rem;
$h3-font-size:                                      2.5rem;
$h4-font-size:                                      2.2rem;
$h5-font-size:                                      1.9rem;
$h6-font-size:                                      1.6rem;
