.c-banner {
    &__content {
        & img:first-child {
            width: 100%;
            height: 80px;
        }
    }

    &__money {
        &_card {
            &s {
                display: flex;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);

                top: -2px;
                overflow-x: hidden;
                margin-top: 100px;
                height: 25vh;
                max-height: 80px;
            }

            background-repeat: no-repeat;
            display: flex;
            position: relative;

            &_major {
                background-image: url(../img/major-s-1.webp);
                background-image: image-set(url(../img/major-s-1.webp) 1x, url(../img/major-s-2.webp) 2x);
                width: 86px;
                transform: translateY(10px);
                background-size: 86px 45px;
                height: 45px;

                & span span {
                    background: linear-gradient(180deg, #cfc2b4 13%, #fef2df 44%, #d4c4b3 46%, #bbaa9b);
                    -webkit-text-fill-color: #0000;
                    -webkit-background-clip: text !important;
                }
            }

            &_mega {
                background-image: url(../img/mega-s-1.webp);
                background-image: image-set(url(../img/mega-s-1.webp) 1x, url(../img/mega-s-2.webp) 2x);
                width: 95px;
                height: 45px;
                margin: 0 5px;
                background-size: 95px 45px;

                & span span {
                    -webkit-text-fill-color: #0000;
                    background: linear-gradient(180deg, #fff47a, #f4b200 37.5%, #ffc30c 45.31%, #fff47a 99.48%);
                    -webkit-background-clip: text !important;
                }
            }

            &_minor {
                background-image: url(../img/minor-s-1.webp);
                background-image: image-set(url(../img/minor-s-1.webp) 1x, url(../img/minor-s-2.webp) 2x);
                width: 86px;
                transform: translateY(10px);
                background-size: 86px 45px;
                height: 45px;

                & span span {
                    -webkit-text-fill-color: #0000;
                    background: linear-gradient(180deg, #e2e2e2 13%, #b3b3b3 44%, #c1c1c1 46%, #e0e0e0);
                    -webkit-background-clip: text !important;
                }
            }
        }

        &_amount {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 2.5px;
            font-size: 12px;
            white-space: nowrap;
            background-size: calc(100% - 1px) calc(100% - 1px);
            background-position: 50%;
            background-repeat: no-repeat;
            font-weight: 700;
        }
    }

    &__subtitle {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(269.99deg, rgba(6, 16, 79, 0), rgba(6, 16, 79, .6) 33.33%, rgba(6, 16, 79, .6) 66.66%, rgba(6, 16, 79, 0) 99.99%);

        & span {
            font-size: 12px;
            font-weight: 800;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #fff;
            white-space: nowrap;
        }
    }
}