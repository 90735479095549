@charset "UTF-8";
*, *::before, *::after {
  box-sizing: border-box;
}

ul, ol {
  padding: 0;
}

body, h1, h2, h3, h4, h5, h6, p, ul, ol, li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul, ol {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerUltraLight.eot");
  src: local("Muller UltraLight"), local("MullerUltraLight"), url("../fonts/Muller/MullerUltraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerUltraLight.woff2") format("woff2"), url("../fonts/Muller/MullerUltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerBoldItalic.eot");
  src: local("Muller Bold Italic"), local("MullerBoldItalic"), url("../fonts/Muller/MullerBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerBoldItalic.woff2") format("woff2"), url("../fonts/Muller/MullerBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerMedium.eot");
  src: local("Muller Medium"), local("MullerMedium"), url("../fonts/Muller/MullerMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerMedium.woff2") format("woff2"), url("../fonts/Muller/MullerMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerExtraBoldItalic.eot");
  src: local("Muller ExtraBold Italic"), local("MullerExtraBoldItalic"), url("../fonts/Muller/MullerExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerExtraBoldItalic.woff2") format("woff2"), url("../fonts/Muller/MullerExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerUltraLightItalic.eot");
  src: local("Muller UltraLight Italic"), local("MullerUltraLightItalic"), url("../fonts/Muller/MullerUltraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerUltraLightItalic.woff2") format("woff2"), url("../fonts/Muller/MullerUltraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerRegular.eot");
  src: local("Muller Regular"), local("MullerRegular"), url("../fonts/Muller/MullerRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerRegular.woff2") format("woff2"), url("../fonts/Muller/MullerRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerBlackItalic.eot");
  src: local("Muller Black Italic"), local("MullerBlackItalic"), url("../fonts/Muller/MullerBlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerBlackItalic.woff2") format("woff2"), url("../fonts/Muller/MullerBlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Muller Hairline";
  src: url("../fonts/Muller/MullerHairlineItalic.eot");
  src: local("Muller Hairline Italic"), local("MullerHairlineItalic"), url("../fonts/Muller/MullerHairlineItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerHairlineItalic.woff2") format("woff2"), url("../fonts/Muller/MullerHairlineItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerHeavyItalic.eot");
  src: local("Muller Heavy Italic"), local("MullerHeavyItalic"), url("../fonts/Muller/MullerHeavyItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerHeavyItalic.woff2") format("woff2"), url("../fonts/Muller/MullerHeavyItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerThinItalic.eot");
  src: local("Muller Thin Italic"), local("MullerThinItalic"), url("../fonts/Muller/MullerThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerThinItalic.woff2") format("woff2"), url("../fonts/Muller/MullerThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Muller Hairline";
  src: url("../fonts/Muller/MullerHairline.eot");
  src: local("Muller Hairline"), local("MullerHairline"), url("../fonts/Muller/MullerHairline.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerHairline.woff2") format("woff2"), url("../fonts/Muller/MullerHairline.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerHeavy.eot");
  src: local("Muller Heavy"), local("MullerHeavy"), url("../fonts/Muller/MullerHeavy.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerHeavy.woff2") format("woff2"), url("../fonts/Muller/MullerHeavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerThin.eot");
  src: local("Muller Thin"), local("MullerThin"), url("../fonts/Muller/MullerThin.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerThin.woff2") format("woff2"), url("../fonts/Muller/MullerThin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerLightItalic.eot");
  src: local("Muller Light Italic"), local("MullerLightItalic"), url("../fonts/Muller/MullerLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerLightItalic.woff2") format("woff2"), url("../fonts/Muller/MullerLightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerLight.eot");
  src: local("Muller Light"), local("MullerLight"), url("../fonts/Muller/MullerLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerLight.woff2") format("woff2"), url("../fonts/Muller/MullerLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerBlack.eot");
  src: local("Muller Black"), local("MullerBlack"), url("../fonts/Muller/MullerBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerBlack.woff2") format("woff2"), url("../fonts/Muller/MullerBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerBold.eot");
  src: local("Muller Bold"), local("MullerBold"), url("../fonts/Muller/MullerBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerBold.woff2") format("woff2"), url("../fonts/Muller/MullerBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerExtraBold.eot");
  src: local("Muller ExtraBold"), local("MullerExtraBold"), url("../fonts/Muller/MullerExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerExtraBold.woff2") format("woff2"), url("../fonts/Muller/MullerExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muller Regular";
  src: url("../fonts/Muller/MullerRegularItalic.eot");
  src: local("Muller Regular Italic"), local("MullerRegularItalic"), url("../fonts/Muller/MullerRegularItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerRegularItalic.woff2") format("woff2"), url("../fonts/Muller/MullerRegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/Muller/MullerMediumItalic.eot");
  src: local("Muller Medium Italic"), local("MullerMediumItalic"), url("../fonts/Muller/MullerMediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Muller/MullerMediumItalic.woff2") format("woff2"), url("../fonts/Muller/MullerMediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@media screen and (min-width: 1200px) {
  .o-container {
    max-width: 1200px;
  }
}
.o-container {
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
}

.o-wrapper {
  padding: 0 15px;
}

body {
  min-height: 100vh;
}

.o-list {
  list-style: none;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

html {
  font-size: 62.5%;
  scroll-padding-top: 100px;
}

body {
  color: #ffffff;
  font-family: "Muller", sans-serif;
  background-color: #212132;
  font-size: 1.6rem;
}

.svg-none {
  display: none;
}

.o-text-bold {
  font-weight: bold;
}
.o-text-center {
  text-align: center;
}
.o-text-up {
  text-transform: uppercase;
}

img {
  max-width: 100%;
  height: auto;
}

button,
a {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
button picture img:hover,
a picture img:hover {
  transform: scale(1.02);
}

h1,
.h1 {
  font-size: 3rem;
}

h2,
.h2 {
  font-size: 2.8rem;
}

h3,
.h3 {
  font-size: 2.5rem;
}

h4,
.h4 {
  font-size: 2.2rem;
}

h5,
.h5 {
  font-size: 1.9rem;
}

h6,
.h6 {
  font-size: 1.6rem;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: 0.2s;
}

.o-btn {
  align-items: center;
  border: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 40px;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 22px;
  outline: none;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}
.o-btn_white {
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid #fff;
  color: inherit;
  cursor: pointer;
  height: 20px;
  text-decoration: none;
  text-transform: uppercase;
}
.o-btn_red {
  background-color: #fe284a;
  box-shadow: 0 3px 0 0 #871628, 0 4px 4px 0 #000;
  height: 30px;
  padding: 0 30px;
  font-size: 12px;
  border-radius: 50px;
  padding: 0 30px;
  font-weight: 800;
}
.o-btn_red:hover {
  box-shadow: 0 3px 0 0 #871628, 0 4px 4px 0 #000, inset 0 15px 15px -3px rgba(255, 255, 255, 0.4);
}
.o-btn_green {
  background-color: #62c23c;
  box-shadow: 0 3px 0 0 #3c7028, 0 4px 4px 0 #000;
  font-size: 16px;
  font-weight: 600;
  height: 30px;
  padding: 0 30px;
  border-radius: 50px;
}
.o-btn_green:hover {
  box-shadow: 0 3px 0 0 #3c7028, 0 4px 4px 0 #000, inset 0 15px 15px -3px rgba(255, 255, 255, 0.4);
}

.c-header {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 90;
  background-color: #161626;
  height: auto;
  max-height: 100px;
  min-height: 50px;
  padding: 0 20px;
  padding-bottom: 10px;
}
.c-header__content {
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
}
.c-header__logo {
  height: 50px;
}
.c-header__buttons {
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-top: 1px solid #363648;
}
.c-header__help {
  display: flex;
  color: #e8ce3a;
  align-items: center;
  gap: 6px;
}
.c-header__help span {
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 300;
  display: none;
}
.c-header__help:hover {
  color: #f5ea1a;
}

.c-banner__content img:first-child {
  width: 100%;
  height: 80px;
}
.c-banner__money_card {
  background-repeat: no-repeat;
  display: flex;
  position: relative;
}
.c-banner__money_cards {
  display: flex;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  top: -2px;
  overflow-x: hidden;
  margin-top: 100px;
  height: 25vh;
  max-height: 80px;
}
.c-banner__money_card_major {
  background-image: url(../img/major-s-1.webp);
  background-image: image-set(url(../img/major-s-1.webp) 1x, url(../img/major-s-2.webp) 2x);
  width: 86px;
  transform: translateY(10px);
  background-size: 86px 45px;
  height: 45px;
}
.c-banner__money_card_major span span {
  background: linear-gradient(180deg, #cfc2b4 13%, #fef2df 44%, #d4c4b3 46%, #bbaa9b);
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -webkit-background-clip: text !important;
}
.c-banner__money_card_mega {
  background-image: url(../img/mega-s-1.webp);
  background-image: image-set(url(../img/mega-s-1.webp) 1x, url(../img/mega-s-2.webp) 2x);
  width: 95px;
  height: 45px;
  margin: 0 5px;
  background-size: 95px 45px;
}
.c-banner__money_card_mega span span {
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  background: linear-gradient(180deg, #fff47a, #f4b200 37.5%, #ffc30c 45.31%, #fff47a 99.48%);
  -webkit-background-clip: text !important;
}
.c-banner__money_card_minor {
  background-image: url(../img/minor-s-1.webp);
  background-image: image-set(url(../img/minor-s-1.webp) 1x, url(../img/minor-s-2.webp) 2x);
  width: 86px;
  transform: translateY(10px);
  background-size: 86px 45px;
  height: 45px;
}
.c-banner__money_card_minor span span {
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  background: linear-gradient(180deg, #e2e2e2 13%, #b3b3b3 44%, #c1c1c1 46%, #e0e0e0);
  -webkit-background-clip: text !important;
}
.c-banner__money_amount {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.5px;
  font-size: 12px;
  white-space: nowrap;
  background-size: calc(100% - 1px) calc(100% - 1px);
  background-position: 50%;
  background-repeat: no-repeat;
  font-weight: 700;
}
.c-banner__subtitle {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(269.99deg, rgba(6, 16, 79, 0), rgba(6, 16, 79, 0.6) 33.33%, rgba(6, 16, 79, 0.6) 66.66%, rgba(6, 16, 79, 0) 99.99%);
}
.c-banner__subtitle span {
  font-size: 12px;
  font-weight: 800;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  white-space: nowrap;
}

.c-cards {
  background-color: #252536;
  background-repeat: repeat;
  box-shadow: inset 0 5px 25px 0 rgba(0, 0, 0, 0.8);
  margin-top: 22px;
  border-bottom: dashed 1px #ff274a;
  margin-bottom: 40px;
}
.c-cards__content {
  padding: 1px 5px;
}
.c-cards__list {
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0;
}
.c-cards__item {
  width: 25%;
  position: relative;
}
.c-cards__item:not(:last-child)::after {
  position: absolute;
  top: 5px;
  height: calc(100% - 10px);
  width: 1px;
  background-color: #313047;
  right: 0;
  display: block;
  content: "";
}
.c-cards__item_info a {
  font-weight: 800;
  border-radius: 5px;
  font-size: 14px;
  height: 55px;
  display: flex;
  align-items: center;
}
.c-cards__item_info_blue {
  color: #17eada;
}
.c-cards__item_info_blue img {
  height: 20px;
  margin-bottom: 4px;
}
.c-cards__item_info_red {
  color: #de593e;
}
.c-cards__item_info_red img {
  height: 25px;
  margin-bottom: 4px;
}
.c-cards__item_info_green {
  color: #6ef770;
}
.c-cards__item_info_green img {
  height: 25px;
  margin-bottom: 4px;
}
.c-cards__item_info_yellow {
  color: #e8ce3a;
}
.c-cards__item_info_yellow img {
  height: 25px;
  margin-bottom: 4px;
}
.c-cards__item_info span:first-child {
  height: 100%;
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
}

.c-text {
  margin-bottom: 25px;
}
.c-text-container {
  border-radius: 10px;
}
.c-text img {
  margin: auto;
}
.c-text p {
  margin-bottom: 10px;
  font-size: 1.6rem;
}
.c-text ul {
  padding-left: 25px;
  list-style-type: disc;
  font-size: 1.6rem;
  margin-bottom: 15px;
}
.c-text ol {
  padding-left: 25px;
  list-style-type: decimal;
  font-size: 1.6rem;
  margin-bottom: 15px;
}
.c-text a:not([class]) {
  color: #ffeeaa;
  text-decoration: none;
}
.c-text a:not([class]):hover, .c-text a:not([class]):focus {
  text-decoration: underline;
}
.c-text .o-text-image_right {
  float: right;
  max-width: 40vw;
}
.c-text .o-text-image_left {
  float: left;
  max-width: 40vw;
}
.c-text details[open] .c-table-icon {
  rotate: 180deg;
}
.c-text details[open] summary {
  border-radius: 16px 16px 0 0;
}

.c-table-of-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style: decimal;
  flex-direction: column;
  padding: 25px 20px 15px 60px !important;
  background: #1b1b27;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2392156863);
  border-radius: 0 0 16px 16px;
}
.c-table-of-content li {
  margin-bottom: 10px;
  padding-left: 5px;
}
.c-table-of-content li::marker {
  font-weight: 500;
  color: #fe284a;
}
.c-table-of-content a:not([class]) {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
}
.c-table-of-content a:not([class]):hover {
  text-decoration: underline #fe284a;
}
.c-table-of-content__wrapper {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 30px 0;
}
.c-table-of-content__wrapper summary {
  cursor: pointer;
  background-color: #252536;
  font-weight: 700;
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  padding: 10px 20px;
  display: flex;
  z-index: 1;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2392156863);
}
.c-table-of-content__wrapper summary svg {
  width: 18px;
  height: 14px;
  fill: #fff;
  margin-left: 8px;
  transition: rotate 0.15s ease-in;
  flex-shrink: 0;
}
.c-table-of-content__item {
  width: 100%;
  margin-bottom: 10px;
}
.c-table-of-content__item:nth-child(odd) {
  padding-right: 25px;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
table a:not([class]) {
  color: #ffeeaa;
  text-decoration: none;
}
table a:not([class]):hover, table a:not([class]):focus {
  text-decoration: underline;
}
table th, table td {
  padding: 7px 10px;
  border: dashed 1px #ff274a;
  min-width: 120px;
  text-align: center;
}

.c-table__wrapper {
  margin-bottom: 15px;
  width: 100%;
  overflow-x: scroll;
}
.c-table__wrapper::-webkit-scrollbar {
  display: none;
}

.c-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  margin-bottom: 15px;
  float: left;
  width: 50%;
  height: auto;
  transition: 0.2s ease-in-out;
}
.c-slots {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 25px;
}
.c-slots__games_button {
  font-size: 12px;
  height: 30px;
  padding: 0 30px;
  background-color: #4d5a85;
  box-shadow: 0 3px 0 0 #2c3552, 0 4px 4px 0 #000;
  border-radius: 50px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 22px;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  margin: 0 auto;
  margin-bottom: 50px;
  width: 140px;
}
.c-slots__games_button:hover {
  box-shadow: 0 3px 0 0 #2c3552, 0 4px 4px 0 #000, inset 0 15px 15px -3px rgba(255, 255, 255, 0.4);
}
.c-slot__icon {
  width: 100%;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  border: 1px solid #393a51;
  border-bottom: none;
  height: 75%;
  position: relative;
}
.c-slot__icon img {
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
.c-slot__new {
  position: absolute;
  right: 15px;
  top: 10px;
  max-width: 40px;
  margin-left: auto;
  background: #ff5100;
  color: #000000;
  padding: 0 6px;
  margin-bottom: 2px;
  line-height: 26px;
  font-size: 1.1rem;
  border-radius: 3px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.c-slot__name {
  background-color: #161626;
  border: 1px solid #393a51;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: none;
  box-sizing: border-box;
  display: flex;
  flex: none;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  height: 25%;
  justify-content: center;
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 0 5px;
  text-align: center;
  text-overflow: ellipsis;
}
.c-slot:hover .c-slot__overflow {
  opacity: 1;
}
.c-slot:hover {
  scale: 1.1;
}
.c-slot__overflow {
  opacity: 0;
  display: block;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 200ms;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 10;
}
.c-slot__overflow a {
  transition: transform 0.2s ease-in;
}
.c-slot__overflow a:hover {
  transform: scale(1.1);
}
.c-slot__overflow .o-btn_green {
  font-size: 12px;
  padding: 0 10px;
}
.c-slot__overflow-play {
  width: 64px;
  height: 64px;
  margin-bottom: 12px;
  border-radius: 50%;
  transition: all 0.3s linear;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  align-items: center;
}
.c-slot__overflow-play:hover {
  transform: scale(1.03);
}
.c-slot__overflow-demo {
  background-color: #313047;
  border-radius: 50px;
  box-sizing: border-box;
  font-size: 11px;
  font-weight: 700;
  height: 23px;
  line-height: 25px;
  padding: 0 20px;
  text-transform: uppercase;
}
@media screen and (min-width: 668px) {
  .c-slot {
    width: 217px;
    height: 217px;
  }
}
.c-comment {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: dashed 1px #ff274a;
  background-color: #1b1b27;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
.c-comment a:not([class]) {
  color: #ffeeaa;
  text-decoration: none;
}
.c-comment a:not([class]):hover, .c-comment a:not([class]):focus {
  text-decoration: underline;
}
.c-comments {
  margin-bottom: 30px;
}
.c-comment__name {
  width: 100%;
  text-align: left;
  font-size: 2rem;
}
.c-comment__content {
  padding: 10px;
  display: -webkit-inline-box;
  display: inline-flex;
  text-align: left;
  width: 100%;
}
.c-comment .o-btn {
  margin-left: auto;
}

.c-faq {
  margin-bottom: 30px;
}
.c-faq a:not([class]) {
  color: #ffeeaa;
  text-decoration: none;
}
.c-faq a:not([class]):hover, .c-faq a:not([class]):focus {
  text-decoration: underline;
}
.c-faq details[open] summary {
  border-radius: 4px 4px 0 0;
  box-shadow: none;
}
.c-faq details[open] ::before {
  rotate: 90deg;
}
.c-faq__item {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-bottom: 10px;
}
.c-faq__title {
  cursor: pointer;
  padding: 7px;
  padding-left: 30px;
  background-color: #fe284a;
  box-shadow: 0 3px 0 0 #871628, 0 4px 4px 0 #000;
  font-weight: 600;
  font-size: 1.6rem;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px;
  word-break: break-all;
  position: relative;
}
.c-faq__title::-webkit-details-marker {
  display: none;
}
.c-faq__title:before {
  content: "►";
  position: absolute;
  display: -webkit-box;
  display: flex;
  left: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  width: 10px;
  height: 10px;
  transition: rotate 0.4s ease-out;
}
.c-faq__content {
  padding: 10px 15px;
  border: dashed 1px #ff274a;
  background-color: #1b1b27;
  border-top: 0;
  border-radius: 0 0 4px 4px;
}

.c-main {
  max-width: 100%;
}
.c-main h1, .c-main h2, .c-main h3, .c-main h4, .c-main h5, .c-main h6 {
  margin-bottom: 10px;
  line-height: 1.2;
}

.c-footer {
  background: url(../img/footer-bg.webp) repeat, #0a0a14;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
}
.c-footer__upper {
  max-width: 1200px;
  align-items: center;
  display: flex;
  padding-bottom: 20px;
  padding-top: 20px;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
.c-footer__upper_help {
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.c-footer__upper_help a {
  align-items: center;
  border-radius: 5px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  font-size: 16px;
  height: 36px;
  padding: 0 8px;
  text-decoration: none;
  transition: background-color 0.15s linear, transform 0.15s linear;
  margin-right: 8px;
}
.c-footer__upper_help a:hover {
  background-color: #252536;
}
.c-footer__upper_social {
  align-items: center;
  display: flex;
}
.c-footer__upper_social a {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 35px;
  justify-content: center;
  width: 35px;
  margin-right: 10px;
}
.c-footer__upper_social a img {
  height: 20px;
  width: 20px;
}
.c-footer__upper_social_instagram {
  background-color: #264484;
  box-shadow: 0 3px 0 1px #16284d;
}
.c-footer__upper_social_instagram:hover {
  box-shadow: 0 3px 0 1px #16284d, inset 0 10px 10px 5px rgba(255, 255, 255, 0.4);
}
.c-footer__upper_social_telegram {
  background-color: #2ca3df;
  box-shadow: 0 3px 0 1px #1a6b84;
}
.c-footer__upper_social_telegram:hover {
  box-shadow: 0 3px 0 1px #1a6b84, inset 0 10px 10px 5px rgba(255, 255, 255, 0.4);
}
.c-footer__upper_social_vk {
  background-color: #3a6aa3;
  box-shadow: 0 3px 0 1px #223f61;
}
.c-footer__upper_social_vk:hover {
  box-shadow: 0 3px 0 1px #223f61, inset 0 10px 10px 5px rgba(255, 255, 255, 0.4);
}
.c-footer__upper_social_youtube {
  background-color: red;
  box-shadow: 0 3px 0 1px #781712;
}
.c-footer__upper_social_youtube:hover {
  box-shadow: 0 3px 0 1px #781712, inset 0 10px 10px 5px rgba(255, 255, 255, 0.4);
}
.c-footer__lower {
  padding: 0 10px;
  display: block;
  max-width: 1200px;
}
.c-footer__lower_copyright {
  display: block;
  padding-bottom: 20px;
  padding-top: 20px;
}
.c-footer__lower_copyright__info {
  display: block;
}
.c-footer__lower_copyright__lang {
  justify-content: center;
  align-items: center;
  border: none;
  outline: 0;
  padding: 6px;
  background: 0 0;
  margin-bottom: 20px;
  display: flex;
}
.c-footer__lower_copyright__lang svg:first-child {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.c-footer__lower_copyright__lang span {
  color: #fff;
  margin-left: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
.c-footer__lower_copyright__lang svg:last-child {
  width: 12px;
  height: 9px;
  fill: #fff;
  margin-left: 8px;
  margin-top: -5px;
  flex-shrink: 0;
}
.c-footer__lower_copyright__license {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}
.c-footer__lower_copyright__license div {
  min-width: 40px;
}
.c-footer__lower_copyright__license p {
  color: #646a87;
  font-size: 12px;
  margin-left: 20px;
  word-wrap: normal;
  line-height: 16px;
  min-width: 230px;
  word-break: normal;
}
.c-footer__lower_copyright__warning {
  max-width: 380px;
  align-items: center;
  margin-right: 0;
  margin-bottom: 20px;
  display: flex;
}
.c-footer__lower_copyright__warning svg {
  width: 120px;
  height: 40px;
}
.c-footer__lower_copyright__warning p {
  line-height: 14px;
  color: #646a87;
  font-size: 12px;
  margin-left: 20px;
}
.c-footer__lower_payments {
  margin-bottom: 30px;
}
.c-footer__lower_payments__title {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  line-height: 23px;
  font-weight: 700;
}
.c-footer__lower_payments ul {
  background: url(../img/footer-bg.webp) repeat, #0a0a14;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.c-footer__lower_payments ul li {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.031372549);
  border-radius: 5px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: 0 10px 10px 0;
  height: 50px;
  min-width: 50px;
  padding: 10px;
  width: auto;
}
.c-footer__lower_payments ul li svg {
  height: 100%;
  max-height: 40px;
  object-fit: contain;
  width: 60px;
}

@media screen and (min-width: 492px) {
  .c-slot__overflow .o-btn_green {
    font-size: 16px;
    padding: 0 30px;
  }
}
@media screen and (min-width: 668px) {
  html {
    scroll-padding-top: 50px;
  }
  .c-header {
    flex-direction: row;
    background-color: rgba(37, 37, 54, 0.95);
    padding-bottom: unset;
  }
  .c-header__content {
    flex-direction: initial;
  }
  .c-header__buttons {
    margin-left: auto;
    justify-content: unset;
    width: unset;
    border-top: unset;
  }
  .c-header__help span {
    display: flex;
  }
  .c-banner {
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.8);
    max-height: initial;
    margin-top: initial;
    padding-top: initial;
    height: 300px;
  }
  .c-banner__content {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .c-banner__content img:first-child {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
  }
  .c-banner__money_amount {
    font-size: 25px;
  }
  .c-banner__money_cards {
    overflow-x: unset;
    margin-top: 0;
    height: auto;
    max-height: unset;
  }
  .c-banner__money_card_major {
    background-size: 180px 88px;
    height: 88px;
    transform: translateY(20px);
    width: 180px;
  }
  .c-banner__money_card_mega {
    background-size: 203px 88px;
    height: 88px;
    margin: 0 20px;
    width: 203px;
  }
  .c-banner__money_card_minor {
    width: 180px;
    height: 88px;
    transform: translateY(20px);
    background-size: 180px 88px;
  }
  .c-banner__subtitle {
    background: linear-gradient(270.09deg, rgba(6, 16, 79, 0) 0.07%, rgba(6, 16, 79, 0.9019607843) 10.06%, rgba(6, 16, 79, 0.9019607843) 89.94%, rgba(6, 16, 79, 0) 99.93%);
  }
  .c-banner__subtitle span {
    padding-top: initial;
    display: flex;
    align-items: center;
    font-weight: 800;
    height: 56px;
    font-size: 26px;
    line-height: 34px;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background: linear-gradient(180deg, #fff176, #ffc40f 50%, #f5b401 50.01%, #ffee5b);
    -webkit-background-clip: text;
  }
  .c-cards {
    margin-top: unset;
  }
  .c-cards__list {
    margin: 0 auto;
    max-width: 1200px;
  }
  .c-cards__item_info span:first-child {
    font-size: 20px;
    padding-left: initial;
    justify-content: center;
    flex-direction: initial;
  }
  .c-cards__item_info img {
    margin-bottom: initial;
    margin-right: 12px;
  }
  .c-cards__item_info_blue img {
    height: auto;
    width: 52px;
  }
  .c-cards__item_info_red img {
    height: auto;
    width: 40px;
  }
  .c-cards__item_info_green img {
    height: auto;
    width: 37px;
  }
  .c-cards__item_info_yellow img {
    height: auto;
    width: 37px;
  }
  .c-table-of-content {
    flex-direction: row;
  }
  .c-table-of-content__item {
    width: 50%;
  }
  .c-slots {
    max-width: unset;
  }
  .c-footer__upper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    padding-bottom: 30px;
    padding-top: 30px;
    flex-direction: row;
    width: 100%;
    border-bottom: dashed 1px #ff274a;
  }
  .c-footer__upper_help {
    justify-content: unset;
    margin-bottom: unset;
  }
  .c-footer__lower_copyright__info {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }
  .c-footer__lower_copyright__license {
    margin-bottom: unset;
    margin-right: 35px;
  }
  .c-footer__lower_payments ul li {
    height: 60px;
    min-width: 110px;
  }
}
@media screen and (min-width: 1024px) {
  .c-footer__upper {
    padding-right: 10px;
    padding-left: 10px;
  }
  .c-footer__lower {
    padding: 0 20px;
  }
  .c-footer__lower_copyright {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .c-footer__lower_copyright__info {
    max-width: 950px;
    justify-content: flex-start;
  }
}