.c-footer {
    background: url(../img/footer-bg.webp) repeat, #0a0a14;
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: center;

    &__upper {
        max-width: 1200px;
        align-items: center;
        display: flex;
        padding-bottom: 20px;
        padding-top: 20px;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

        &_help {
            justify-content: center;
            margin-bottom: 20px;
            display: flex;
            flex-wrap: wrap;

            & a {
                align-items: center;
                border-radius: 5px;
                box-sizing: border-box;
                color: #fff;
                display: flex;
                font-size: 16px;
                height: 36px;
                padding: 0 8px;
                text-decoration: none;
                transition: background-color .15s linear, transform .15s linear;
                margin-right: 8px;

                &:hover {
                    background-color: #252536;
                }
            }
        }

        &_social {
            align-items: center;
            display: flex;

            & a {
                align-items: center;
                border-radius: 50%;
                display: flex;
                height: 35px;
                justify-content: center;
                width: 35px;
                margin-right: 10px;

                & img {
                    height: 20px;
                    width: 20px;
                }
            }

            &_instagram {
                background-color: #264484;
                box-shadow: 0 3px 0 1px #16284d;

                &:hover {
                    box-shadow: 0 3px 0 1px #16284d, inset 0 10px 10px 5px #fff6;
                }
            }

            &_telegram {
                background-color: #2ca3df;
                box-shadow: 0 3px 0 1px #1a6b84;

                &:hover {
                    box-shadow: 0 3px 0 1px #1a6b84, inset 0 10px 10px 5px #fff6;
                }
            }

            &_vk {
                background-color: #3a6aa3;
                box-shadow: 0 3px 0 1px #223f61;

                &:hover {
                    box-shadow: 0 3px 0 1px #223f61, inset 0 10px 10px 5px #fff6;
                }
            }

            &_youtube {
                background-color: red;
                box-shadow: 0 3px 0 1px #781712;

                &:hover {
                    box-shadow: 0 3px 0 1px #781712, inset 0 10px 10px 5px #fff6;
                }
            }
        }
    }

    &__lower {
        padding: 0 10px;
        display: block;
        max-width: 1200px;

        &_copyright {
            display: block;
            padding-bottom: 20px;
            padding-top: 20px;

            &__info {
                display: block;
            }

            &__lang {
                justify-content: center;
                align-items: center;
                border: none;
                outline: 0;
                padding: 6px;
                background: 0 0;
                margin-bottom: 20px;
                display: flex;

                & svg:first-child {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                & span {
                    color: #fff;
                    margin-left: 1rem;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                & svg:last-child {
                    width: 12px;
                    height: 9px;
                    fill: #fff;
                    margin-left: 8px;
                    margin-top: -5px;
                    flex-shrink: 0;
                }
            }

            &__license {
                align-items: center;
                display: flex;
                margin-bottom: 20px;

                & div {
                    min-width: 40px;
                }

                & p {
                    color: #646a87;
                    font-size: 12px;
                    margin-left: 20px;
                    word-wrap: normal;
                    line-height: 16px;
                    min-width: 230px;
                    word-break: normal;
                }
            }

            &__warning {
                max-width: 380px;
                align-items: center;
                margin-right: 0;
                margin-bottom: 20px;
                display: flex;

                & svg {
                    width: 120px;
                    height: 40px;
                }

                & p {
                    line-height: 14px;
                    color: #646a87;
                    font-size: 12px;
                    margin-left: 20px;
                }
            }
        }

        &_payments {
            margin-bottom: 30px;

            &__title {
                align-items: center;
                cursor: pointer;
                display: flex;
                justify-content: center;
                gap: 10px;
                font-size: 16px;
                line-height: 23px;
                font-weight: 700;
            }

            & ul {
                background: url(../img/footer-bg.webp) repeat, #0a0a14;
                display: flex;
                flex-wrap: wrap;
                margin-top: 30px;

                & li {
                    align-items: center;
                    background-color: #ffffff08;
                    border-radius: 5px;
                    display: flex;
                    flex-grow: 1;
                    justify-content: center;
                    margin: 0 10px 10px 0;
                    height: 50px;
                    min-width: 50px;
                    padding: 10px;
                    width: auto;

                    & svg {
                        height: 100%;
                        max-height: 40px;
                        object-fit: contain;
                        width: 60px;
                    }
                }
            }
        }
    }
}