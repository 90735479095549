.o-container {
   margin: 0 auto;
   padding: 0 10px;
   width: 100%;
}

.o-wrapper {
   padding: 0 15px;
}

body {
   min-height: 100vh;
}

.o-list {
   list-style: none;
   list-style-type: none;
   display: flex;
   flex-wrap: wrap;
}