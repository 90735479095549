.c-header {
	height: 50px;
	position: sticky;
	top: 0;
	z-index: 90;
	background-color: #161626;
	height: auto;
	max-height: 100px;
	min-height: 50px;
	padding: 0 20px;
	padding-bottom: 10px;

	&__content {
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		box-sizing: border-box;
		display: flex;
		max-width: 1200px;
		margin: 0 auto;
	}

	&__logo {
		height: 50px;
	}

	&__buttons {
		display: flex;
		gap: 14px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 50px;
		border-top: 1px solid #363648;
	}

	&__help {
		display: flex;
		color: #e8ce3a;
		align-items: center;
		gap: 6px;

		& span {
			position: relative;
			top: 2px;
			font-size: 16px;
			font-weight: 300;
			display: none;
		}

		&:hover {
			color: #f5ea1a;
		}
	}
}