.c-faq {
	& a:not([class]) {
		color: $link-hover;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	margin-bottom: 30px;

	& details[open] summary {
		border-radius: 4px 4px 0 0;
		box-shadow: none;
	}

	& details[open] ::before {
		rotate: 90deg;
	}

	&__item {
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		margin-bottom: 10px;
	}

	&__title {
		cursor: pointer;
		padding: 7px;
		padding-left: 30px;
		background-color: #fe284a;
		box-shadow: 0 3px 0 0 #871628, 0 4px 4px 0 #000;
		font-weight: 600;
		font-size: 1.6rem;
		display: -webkit-inline-box;
		display: inline-flex;
		-webkit-box-align: center;
		align-items: center;
		border-radius: 4px;
		word-break: break-all;
		position: relative;

		&::-webkit-details-marker {
			display: none;
		}

		&:before {
			content: '►';
			position: absolute;
			display: -webkit-box;
			display: flex;
			left: 10px;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
			text-align: center;
			font-weight: 600;
			color: $white;
			width: 10px;
			height: 10px;
			transition: rotate .4s ease-out;
		}
	}

	&__content {
		padding: 10px 15px;
		border: dashed 1px #ff274a;
		background-color: #1b1b27;
		border-top: 0;
		border-radius: 0 0 4px 4px;
	}
}